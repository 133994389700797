@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

:root {
  /* colors */
  --darkest: #133c55;
  --dark: #386fa4;
  --medium: #59A5D8 ;
  --light: #84D2F6;
  --lightest: #91E5F6;
  --navbar: #90e0ef;
  --link: #042CBC;
  /* fonts */
  --header: 'Lobster', cursive;
  --subheader: 'VT323', monospace;
  --typewriter: 'Cardo', serif;
  /* border radius */
  --bRad: 40px;
}

html {
  background-color: var(--darkest);
}

h1 {
  font-family: var(--header);
}


h2 {
  font-family: var(--subheader);
  padding-bottom: 1.5%; 
  text-align: center;
           
}

h3 {
  font-family: var(--header);
  font-style: oblique;
  padding-top: 1.5%; 
  text-align: center;
           
}

h4 {
  font-family: var(--subheader);
  text-decoration: underline;
  text-align: center;
  margin-bottom: 1.25%;

}

h5 {
  font-family: var(--subheader);
  text-align: center;
}

h6 {
  font-family: var(--subheader);
  text-align: center;
}

p { 
  font-family: var(--typewriter)
}

td {
  text-align: left;
}



.nav-dropdown {
  margin: 0 3.5%; 
  font-family: var(--typewriter);
  font-size: large;
  color: var(--darkest)

}

.nav-dropdown .dropdown-toggle {
  color: var(--navbar);
  font-family: var(--header);
  font-size: x-large;


}

.infoBox1 {
  background-color: var(--medium); 
  border: 3.5px solid var(--darkest);
  text-align:justify;
  padding: 1.25% 5%;
  border-radius: var(--bRad);
  
}
.infoBox1 a {
  color: var(--link)
}


.infoBox2 {
  background-color: var(--lightest); 
  border: 3.5px solid var(--darkest);
  text-align:justify;
  padding: 1.25% 5%;
  border-radius: var(--bRad);

}

.float-container, .float-container-dean {
  display:flex; 
  flex-direction: column; 
  align-items: center; 
  
}

.float-container {
  justify-content: center;
}

.float-containr img {
  margin: 0 auto;
}

.float-conatiner-dean {
  padding: 0 5%;
}

.row-center {
  display: flex;
  justify-content: center;

}

.college-symbol {
  width: 25%;
  margin-bottom: 2.5%;
}

.map-img {
  width: 70%; 
  margin-bottom:2.5%;
}

.student-portait {
  width: 65%;
  margin-bottom:2.5%;

}

.table-border {
  border: 3px solid var(--darkest); 
  padding: 10px;
  text-align: center;
}

.student-info {
  text-align: center;
  width: 85%;
  margin: 0 auto;
}

.student-info p {
  line-height: 1.1;
}

table { 
  border: 2.5px solid var(--darkest);
  border-radius: var(--bRad);
  font-family: var(--typewriter);
  margin: 0 auto;
  margin-bottom: 5%;
}

th {
  font-family: var(--subheader);
  font-size: x-large;
}

ul {
  font-family: var(--typewriter);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

li {
  width: 90%;
}


@media (max-width: 968px) {

  p {
    text-align: center;
  }
  .container {
    height: auto;
  }

  .college-symbol {
    width: 85%;
  }
  .map-img {
    width: 100%; 
  
  }

  .float-right-order {
    order: 0
  }
  .float-left-order {
    order: 1
  }
}